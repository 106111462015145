import React from "react"
import Layout from "../components/layout"

const About = () => (
  <Layout>
    <h1>Privacy policy</h1>
    <p>
      <i>Versie mei 2018</i>
    </p>

    <h4>Algemeen</h4>
    <p>
      Balans in hoofd en huis respecteert de privacy van al haar cliënten en zal
      er alles aan doen om zorgvuldig met persoonsgegevens om te gaan. Balans in
      hoofd en huis houdt zich in alle gevallen aan de toepasselijke wet- en
      regelgeving, waaronder de Algemene Verordening Gegevensbescherming.
    </p>

    <h4>Waarom verzamelt Balans in hoofd en huis informatie?</h4>
    <p>
      Persoonsgegevens van cliënte worden door Balans in hoofd en huis verwerkt
      ten behoeve van de volgende doelstellingen:
    </p>
    <ul>
      <li>Administratieve doeleinde</li>
      <li>Communicatie over de opdracht en/of uitnodigingen</li>
      <li>Het uitvoering geven aan of het uitgeven van de opdracht</li>
    </ul>
    <p>
      Balans in hoofd en huis kan de volgende persoonsgegevens aan u vragen:
    </p>
    <ul>
      <li> Voornaam</li>
      <li> Tussenvoegsel</li>
      <li> Achternaam</li>
      <li> Adres</li>
      <li> Telefoonnummer</li>
      <li> E-mailadres</li>
      <li> Geslacht</li>
      <li> BSN-nummer</li>
      <li> Naam van huisarts</li>
      <li> Naam van betrokken hulpverleners</li>
      <li> Contactgegevens van naastbetrokkenen</li>
      <li> Psychiatrische onderzoeksrapporten</li>
    </ul>

    <p>
      Uw persoonsgegevens worden door Balans in hoofd en huis opgeslagen ten
      behoeve van bovengenoemde verwerking(en) voor de periode:
    </p>
    <ul>
      <li>
        Gedurende de looptijd van de overeenkomst en daarna is het nog 15 jaar
        opgeslagen in het rapportagesysteem ONS van Nedap, waarna het verwijderd
        wordt.
      </li>
      <li>
        Informatie uit het digitale dossier is door cliënten tijdens de 15 jaar
        altijd op te vragen.
      </li>
      <li>
        Balans in hoofd en huis deelt geen informatie met derden. Gegevens van
        cliënten worden niet verstrekt aan anderen. Wanneer de betrokken cliënt
        op papier toestemming geeft aan Balans in hoofd en huis om informatie te
        verstrekken, kunnen derden informatie opvragen.{" "}
      </li>
    </ul>

    <p>
      Wanneer Balans in hoofd en huis geen zorg kan leveren vanwege vakantie of
      ziekte wordt een onderaannemer ingehuurd die gemachtigd is om ook
      bovenstaande persoonsgegevens in te kunnen zien om de juiste zorg te
      kunnen leveren in afwezigheid van Balans in hoofd en huis.
    </p>

    <h4>Verwerking van persoonsgegevens van onderaannemers</h4>
    <p>
      Persoonsgegevens van onderaannemers worden door Balans in hoofd en huis
      verwerkt ten behoeve van de volgende doelstelling(en):
    </p>
    <ul>
      <li>Uitvoering geven aan de arbeidsovereenkomst</li>
    </ul>
    <p>Grondslag voor deze persoonsgegevens is:</p>
    <ul>
      <li>Overeenkomst onderaannemerschap</li>
    </ul>

    <p>
      Voor de bovenstaande doelstelling(en) kan Balans in hoofd en huis de
      volgende persoonsgegevens van u vragen:
    </p>

    <ul>
      <li> Voornaam</li>
      <li> Tussenvoegsel</li>
      <li> Achternaam</li>
      <li> Adres</li>
      <li> Telefoonnummer</li>
      <li> E-mailadres</li>
      <li> Bankgegevens</li>
    </ul>

    <p>
      Uw persoonsgegevens worden door Balans in hoofd en huis opgeslagen ten
      behoeve van bovengenoemde verwerking(en) voor de periode:
    </p>
    <ul>
      <li>
        Gedurende de periode dat men een contract heeft en daarna alleen in het
        rapportagesysteem ONS van Nedap voor de duur van 15 jaar.
      </li>
    </ul>

    <h4>Cookies, of vergelijkbare technieken die gebruikt worden</h4>
    <p>
      Balans in hoofd en huis gebruikt alleen technische en functionele cookies.
      En analytische cookies die geen inbreuk maken op uw privacy. Een cookie is
      een klein tekstbestand dat bij het eerste bezoek aan deze website wordt
      opgeslagen op uw computer, tablet of smartphone. De cookies die wij
      gebruiken zijn noodzakelijk voor de technische werking van de website en
      uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en
      onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee
      onze website optimaliseren. U kunt zich afmelden voor cookies door uw
      internetbrowser zo in te stellen dat deze geen cookies meer opslaat.
      Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de
      instellingen van uw browser verwijderen.
    </p>

    <h4>Verstrekking aan derden</h4>

    <p>
      De gegevens die u aan ons geeft kunnen wij aan derde partijen verstrekken
      indien dit noodzakelijk is voor uitvoering van de hierboven beschreven
      doeleinden.
    </p>

    <p>Zo maken wij gebruik van een derde partij voor:</p>

    <ul>
      <li> Rapportagesysteem</li>
      <li> Het verzorgen van de internetomgeving van het AVG-programma</li>
      <li> Het verzorgen van de (financiële) administratie</li>
      <li>
        De financier kan gegevens opvragen over de inhoud van de zorg. Dit wordt
        gegeven na uitdrukkelijke toestemming van de cliënt.
      </li>
    </ul>

    <p>
      Wij geven nooit persoonsgegevens door aan andere partijen waarmee we geen
      verwerkersovereenkomst hebben afgesloten. Met deze partijen (verwerkers)
      maken wij hierin uiteraard de nodige afspraken om de beveiliging van uw
      persoonsgegevens te waarborgen. Verder zullen wij de door uw verstrekte
      gegevens niet aan andere partijen verstrekken, tenzij dit wettelijk
      verplicht is toegestaan.
    </p>

    <h4>Bewaartermijn</h4>
    <p>
      Balans in hoofd en huis bewaart persoonsgegevens niet langer dan
      noodzakelijk voor het doel waarvoor deze zijn verstrekt dan wel op grond
      van de wet is vereist.
    </p>

    <h4>Gegevens inzien, aanpassen of verwijderen</h4>
    <p>
      U kunt een verzoek tot inzage, correctie, verwijdering,
      gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van
      uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen
      naar{" "}
      <a href="mailto:info@balansinhoofdenhuis.nl">
        info@balansinhoofdenhuis.nl
      </a>
      . Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan,
      vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te
      sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de
      strook met nummers onderaan het paspoort), paspoortnummer en
      Burgerservicenummer (BSN) zwart, dit ter bescherming van uw privacy. We
      reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek.
    </p>

    <h4>Klacht indienen</h4>
    <p>
      Balans in hoofd en huis wil u erop wijzen dat u de mogelijkheid heeft om
      een klacht in te dienen bij de nationale toezichthouder, de autoriteit
      Persoonsgegevens. Dat kan via de{" "}
      <a
        href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
        target="blank"
      >
        Autoriteit Persoonsgegevens
      </a>
      .
    </p>

    <h4>Beveiligen persoonsgegevens</h4>
    <p>
      Balans in hoofd en huis neemt de bescherming van uw gegevens serieus en
      neemt passende maatregelen om misbruik, verlies, onbevoegde toegang,
      ongewenste openbaarmaking en ongeoorloofde wijzigingen tegen te gaan. Als
      u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er
      aanwijzingen zijn van misbruik, neem dan contact op via e-mail:{" "}
      <a href="mailto:info@balansinhoofdenhuis.nl">
        info@balansinhoofdenhuis.nl
      </a>
      .
    </p>

    <h4>Tot slot</h4>
    <p>
      Dit privacy beleid kan wijzigen als nieuwe ontwikkelingen daartoe
      aanleiding geven. Het meest actuele privacy beleid vind u altijd op
      www.balansinhoofdenhuis.nl Balans in hoofd en huis raadt u aan dit beleid
      geregeld te raadplegen, zodat u optimaal op de hoogte bent. De laatste
      datum van wijziging wordt in het privacy beleid vermeld.
    </p>
  </Layout>
)

export default About
