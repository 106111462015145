import React from "react"
import { Link } from "gatsby"
import LogoS from "../images/logo-s.png"
import LogoL from "../images/logo-l.png"

import "./layout.scss"

const Layout = ({ children }) => {
  return (
    <div className="site">
      <nav className="navbar navbar-expand-md navbar-light">
        <div className="container desktop d-none d-md-flex pb-5">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active-nav-link"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active-nav-link"
                to="/particulieren"
              >
                Voor wie
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active-nav-link"
                to="/verwijzers/"
              >
                Verwijzers
              </Link>
            </li>
          </ul>

          <Link to="/" className="navbar-brand logo">
            <img src={LogoL} alt="" />
          </Link>

          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active-nav-link"
                to="/werkwijze/"
              >
                Werkwijze
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active-nav-link"
                to="/over-mij/"
              >
                Over
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active-nav-link"
                to="/contact/"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>

        <div className="mobile d-md-none pb-4 w-100">
          <div className="container">
            <Link to="/" className="navbar-brand logo">
              <img src={LogoS} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarContent"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarContent">
            <div className="container">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active-nav-link"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active-nav-link"
                    to="/particulieren"
                  >
                    Voor wie
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active-nav-link"
                    to="/verwijzers/"
                  >
                    Verwijzers
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active-nav-link"
                    to="/werkwijze/"
                  >
                    Werkwijze
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active-nav-link"
                    to="/over-mij/"
                  >
                    Over
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active-nav-link"
                    to="/contact/"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div className="container page">
        <main>{children}</main>
      </div>
    </div>
  )
}

export default Layout
